.modal-container {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */  									   /* Enable scroll if needed */  
    background-color: rgb(0,0,0); /* Fallback color */   
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */   
    padding-top: 20vh;
    padding-left: 30vw;
    padding-right: 30vw;
   

} 
.modal {     
    top: '50%';
    left: '50%';
    margin-right: '-50%';
    right: 'auto';
    bottom: 'auto';
    transform: 'translate(-50%, -50%)';
    margin: auto;  
    padding: 20px;   
    border: 1px solid #888;   width: 80%; 
    color: black;
} 
    .hidden { display : none;}

@media only screen and (max-width: 600px) {
    .modal-container {
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */  									   /* Enable scroll if needed */  
        background-color: rgb(0,0,0); /* Fallback color */   
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */   
        padding-top: 20vh;
        padding-left: 10vw;
        padding-right: 10vw;
       
    
    } 
 }